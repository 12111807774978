import Choices from 'choices.js';
export default function choices () {

    const selects = document.querySelectorAll('.modal-select');
    const reset = document.querySelector("#btn-reset");
    const mountedSelects = [];

    selects.forEach(item => {
        mountedSelects.push(new Choices(item, {
            allowHTML: true
        }));
    });

    reset.addEventListener('click', (e) => {
        e.preventDefault();
        
        mountedSelects.forEach(el => {
            el.setChoiceByValue("");
    })
        document.querySelectorAll('.search-input').forEach(el => {
            el.value = "";
            window.setTimeout(() => document.querySelector('.search-input__title').focus(), 0);
        });
}) 
}