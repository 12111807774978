import choices from "./components/choices";
choices();
let menuBtn = document.querySelector('.menu-btn');
let menu = document.querySelector('.menu--mobile');
menuBtn.addEventListener('click', () => {
	menuBtn.classList.toggle('active');
    menu.classList.toggle('active');
    document.body.classList.toggle('active-body');
})
document.addEventListener("DOMContentLoaded", () => {
    var scrollbar = document.body.clientWidth - window.innerWidth;

    document.querySelectorAll('.button-search[href="#openModal"]').forEach((el)=>{
		el.addEventListener('click', () => {
			document.body.classList.add('active-body');
			document.body.style.paddingRight = -scrollbar + 'px';
			document.querySelector('#openModal').style.marginLeft = scrollbar +'px';
			window.setTimeout(() => document.querySelector('.search-input__title').focus(), 0);
			menuBtn.classList.remove('active');
			menu.classList.remove('active');
		});		
	})

    document.querySelector('[href="#close"]').addEventListener('click', () =>{
		document.body.classList.remove('active-body');
		document.body.style.paddingRight = '0px';
		document.querySelector('#openModal').style.marginLeft = '0px';
    });

	window.addEventListener("keydown", (e) => {   
		if (e.key === 'Escape') {
			e.preventDefault();
			document.body.classList.remove('active-body');
			document.body.style.paddingRight = '0px';
			document.querySelector('#openModal').style.marginLeft = '0px';
			return location.hash = '#close'
		}
	});
	document.querySelector('.modal').addEventListener( 'click', (e) => {
		const withinBoundaries = e.composedPath().includes(document.querySelector('.modal-dialog'));
		if ( ! withinBoundaries ) {
			document.body.classList.remove('active-body');
			document.body.style.paddingRight = '0px';
			document.querySelector('#openModal').style.marginLeft = '0px';
			return location.hash = '#close'
		}
	});
});

class DataList {
	constructor(containerId, inputId, listId, options) {
		this.containerId = containerId;
		this.inputId = inputId;
		this.listId = listId;
		this.options = options;
	}

	create(filter = "") {
		const list = document.getElementById(this.listId);
		const filterOptions = this.options.filter(
			d => filter === "" || d.text.includes(filter)
		);

		if (filterOptions.length === 0) {
			list.classList.remove("active");
		} else {
			list.classList.add("active");
		}

		list.innerHTML = filterOptions
			.map(o => `<li id=${o.value}>${o.text}</li>`)
			.join("");
	}

	addListeners(datalist) {
		const container = document.getElementById(this.containerId);
		const input = document.getElementById(this.inputId);
		const list = document.getElementById(this.listId);
		document.querySelector('.modal').addEventListener("click", e => {
			if (e.target.id != this.inputId) {
				container.classList.remove('active');
			}
		});
		container.addEventListener("click", e => {

			if (e.target.id === this.inputId) {
				container.classList.toggle("active");
			} else if (e.target.id === "datalist-icon") {
				container.classList.toggle("active");
				input.focus();
			}
		});
		input.addEventListener("input", () => {
			if (!container.classList.contains("active")) {
				container.classList.add("active");
			}

			datalist.create(input.value);
		});

		list.addEventListener("click", function(e) {
			if (e.target.nodeName.toLocaleLowerCase() === "li") {
				input.value = e.target.innerText;
				container.classList.remove("active");
			}
		});
	}
}

const data1 = [
	{ value: "1", text: "1" },
	{ value: "2", text: "2" },
	{ value: "3", text: "3" },
	{ value: "4", text: "4" },
	{ value: "5", text: "5" },
	{ value: "6", text: "6" },
	{ value: "7", text: "7" },
	{ value: "8", text: "8" },
    { value: "9", text: "9" },
    { value: "10", text: "10" },
    { value: "11", text: "11" },
    { value: "12", text: "12" },
    { value: "13", text: "13" },
    { value: "14", text: "14" },
    { value: "15", text: "15" },
    { value: "16", text: "16" },
    { value: "17", text: "17" },
    { value: "18", text: "18" },
    { value: "19", text: "19" },
    { value: "20", text: "20" },
    { value: "21", text: "21" },
    { value: "22", text: "22" },
    { value: "23", text: "23" },
    { value: "24", text: "24" },
    { value: "25", text: "25" },
    { value: "26", text: "26" },
    { value: "27", text: "27" },
    { value: "28", text: "28" },
    { value: "29", text: "29" },
    { value: "30", text: "30" },
    { value: "31", text: "31" },
];
const data2 = [
	{ value: "1", text: "1" },
	{ value: "2", text: "2" },
	{ value: "3", text: "3" },
	{ value: "4", text: "4" },
	{ value: "5", text: "5" },
	{ value: "6", text: "6" },
	{ value: "7", text: "7" },
	{ value: "8", text: "8" },
    { value: "9", text: "9" },
    { value: "10", text: "10" },
    { value: "11", text: "11" },
    { value: "12", text: "12" }
];

const datalist1 = new DataList(
	"datalist-1",
	"datalist-input-1",
	"datalist-ul-1",
	data1
);
const datalist2 = new DataList(
	"datalist-2",
	"datalist-input-2",
	"datalist-ul-2",
	data2
);
datalist1.create();
datalist1.addListeners(datalist1);
datalist2.create();
datalist2.addListeners(datalist2);


document.querySelectorAll('.site-map__link--lv1').forEach((el) => {
    el.addEventListener('click', () => {
        let content = el.nextElementSibling;
		if(content.style.maxHeight) {
            document.querySelectorAll('.site-map--lv2').forEach((el) => el.style.maxHeight = null);
			document.querySelectorAll('.site-map__link--lv1').forEach((el) => el.classList.remove('lv1--active'));

        } else {
            document.querySelectorAll('.site-map--lv2').forEach((el) => el.style.maxHeight = null);
			document.querySelectorAll('.site-map__link--lv1').forEach((el) => el.classList.remove('lv1--active'));
            content.style.maxHeight = content.scrollHeight + "px";
			el.classList.add('lv1--active');
        }
    })
});
document.querySelectorAll('.navigation__link').forEach((el) => {
	el.addEventListener('click', () => {
		var data = el.getAttribute('data');
		var block = document.querySelector('.default-block-documents[data-content="' + data + '"]');
		var path = document.querySelector('.page-path[data-content="' + data + '"]');
		document.querySelectorAll('.navigation__link').forEach((el) => {
			el.classList.remove('active');
		})
		el.classList.add('active');
		document.querySelectorAll('.default-block-documents[data-content]').forEach((el) => {
			el.classList.remove('active');
		});
		document.querySelectorAll('.page-path[data-content]').forEach((el) => {
			el.classList.remove('active');
		});
		block.classList.add('active');
		path.classList.add('active');
	});
});
function yaMaps() {
    if(document.querySelector('#map')) {
        ymaps.ready(init);
        function init() {
          let myMap = new ymaps.Map('map', {
            center: [54.996547, 73.383501],
            zoom: 17,
            controls: []
          });
          myMap.controls.add('zoomControl', {
            position: {
              top: 8,
              left: 8
            },
            size: 'small'
          });
          let myPlacemark = new ymaps.Placemark(myMap.getCenter(), {
            hintContent: 'ул. Яковлева, 181, Омск, Омская обл., 644007',
            balloonContent: 'ул. Яковлева, 181, Омск, Омская обл., 644007'
          }, {
            iconLayout: 'default#image',
            iconImageHref: '../img/svg/pin.svg',
            iconImageSize: [57, 70],
			iconOffset: [-23, -35]
          });
          myMap.geoObjects.add(myPlacemark);
          if (window.innerWidth < 1025) {
            myMap.behaviors.disable('drag');
        }
        }
      }
}

yaMaps();
var search = document.querySelector('.search__input-search'),
	searchBtn = document.querySelector('.search__button');
	searchBtn.disabled = true
	search.addEventListener('input', ()=>{

	if (search.value != '') {
		searchBtn.disabled = false
	} else {
		searchBtn.disabled = true
	}
})